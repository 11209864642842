type PayementModalCallback = (interventionId: string) => void | Promise<void>

export const useInterventionPayementModal = defineStore('intervention-payement-modal', () => {
  const currentId = ref<string>()
  const openListeners = new Set<PayementModalCallback>()
  const successListeners = new Set<PayementModalCallback>()
  const successOnceListeners = new Set<PayementModalCallback>()
  const cancelListeners = new Set<PayementModalCallback>()

  function open(interventionId: string | null | undefined, successCallback?: PayementModalCallback) {
    if (!interventionId)
      return
    if (successCallback) {
      onceSuccess(successCallback)
    }
    currentId.value = interventionId
    openListeners.forEach(listener => listener(interventionId))
  }

  function success() {
    const id = currentId.value
    if (!id)
      return

    currentId.value = undefined
    successListeners.forEach(listener => listener(id))
    successOnceListeners.forEach(listener => listener(id))
    successOnceListeners.clear()
  }

  function cancel() {
    const id = currentId.value
    if (!id)
      return

    currentId.value = undefined
    cancelListeners.forEach(listener => listener(id))
  }

  function onceSuccess(callback: PayementModalCallback) {
    successOnceListeners.add(callback)

    function stop() {
      successOnceListeners.delete(callback)
    }

    if (getCurrentScope()) {
      onScopeDispose(stop)
    }

    return stop
  }

  function onSuccess(callback: PayementModalCallback) {
    successListeners.add(callback)

    function stop() {
      successListeners.delete(callback)
    }

    if (getCurrentScope()) {
      onScopeDispose(stop)
    }

    return stop
  }
  function onCancel(callback: PayementModalCallback) {
    cancelListeners.add(callback)

    function stop() {
      cancelListeners.delete(callback)
    }

    if (getCurrentScope()) {
      onScopeDispose(stop)
    }

    return stop
  }
  function onOpen(callback: PayementModalCallback) {
    openListeners.add(callback)

    function stop() {
      openListeners.delete(callback)
    }

    if (getCurrentScope()) {
      onScopeDispose(stop)
    }

    return stop
  }

  return {
    currentId: readonly(currentId),
    open,
    success,
    cancel,

    onceSuccess,
    onOpen,
    onSuccess,
    onCancel,
  }
})
